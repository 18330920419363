<template>
	<div class="modal fade" id="propuesta" data-backdrop="static" role="dialog" aria-labelledby="propuestaLabel" aria-hidden="true">
		<div :class="{'modal-dialog modal-dialog-centered modal-dialog-scrollable':true, 'modal-lg':prenda.inversionista == 2}" role="document">
			<form ref="formPropuesta" v-on:submit.prevent="sendPropuesta()" class="needs-validation was-validated w-100" novalidate>
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title text-center w-100">¿Estás seguro que desear realizar una oferta?</h5>
				</div>
				<div class="modal-body">
					<div class="bg-light rounded p-3">
						<div class="row">
							<div class="col-md-5" v-if="prenda.inversionista == 2">
								<h3 style="text-align: center;">Inversión disponible</h3>
								<Chart type="pie" :data="chartdata" :options="chartoptions"></Chart>
							</div>
							<div :class="{'col-md-7':prenda.inversionista == 2, 'col-md-12':prenda.inversionista != 2}">
								<div class="text-danger" v-if="prenda.montoPendiente <= 0 && prenda.inversionista == 2">
									Ya se han completado las ofertas para esta publicación, puedes ofertar el 100% de la inversión.
									<br>
									<br>
								</div>
								<table style="text-align: left; width: 100%;" class="mb-3 table">
									<tbody>
										<tr>
											<th>Monto:</th>
											<td align="right">{{$filters.currency(prenda.precio || 0)}}</td>
										</tr>
										<tr>
											<th>Tasa de interés mensual:</th>
											<td align="right">{{prenda.interes}}%</td>
										</tr>
										<tr>
											<th>Tiempo de pago (mensual):</th>
											<td align="right">{{prenda.pago}}</td>
										</tr>
										<tr>
											<th>Fecha firma de contratos:</th>
											<td align="right">
												{{$filters.date(prenda.fechaContratos)}}
											</td>
										</tr>
										<tr>
											<th>Tipo de desembolso:</th>
											<td align="right">{{prenda.tipoDesembolso}}</td>
										</tr>
									</tbody>
								</table>
								
								<div v-if="prenda.inversionista==2">
									<Currency type="text" v-model="form.montoConfirmacion" class="form-control currency" real-time-currency placeholder="Confirmar monto" ref="montoConfirmacion" :min="montoMinimo" />
									<div class="text-right">
										<small>*Monto mínimo {{$filters.currency(montoMinimo || 0)}}</small>
									</div>
								</div>
								<div class="text-danger">
									{{errorMontoConfirmacion}}
								</div>
							</div>
						</div>
					</div>
				</div>
		    	<div class="modal-footer text-right">
		    		<button type="submit" class="btn btn-primary" :disabled="loadingPublicacion || !validateForm">
		    			<BtnLoading v-if="loadingPublicacion" />
						<span v-else>
							Confirmar
						</span>
		    		</button>
		    		<button class="btn btn-link" data-dismiss="modal">
		        		Cancelar
		    		</button>
		    	</div>
		  	</div>
		  	</form>
		</div>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'

	const Currency = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Currency" */"@/components/Currency.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	const BtnLoading = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "BtnLoading" */"@/components/BtnLoading.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const Chart = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "ChartsChart" */"@/components/charts/Chart.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	export default {
		components: {
			BtnLoading,
			Currency,
			Chart
		},
		data(){
		  return {
		  	loadingPublicacion: false,
		  	validateForm: false,
		  	redirectIndex: true,
		  	form: {
		  		montoConfirmacion: 0,
		  	},
		  	errorMontoConfirmacion: ''
		  }
		},
		mounted(){
			window.$(this.$el).modal('show')
			var vm = this
			window.$(this.$el).on('hidden.bs.modal', function () {
				if (vm.redirectIndex) {
					vm.toIndex()
				}
			})
			this.checkValidity()
		},
		methods: {
			sendPropuesta(){
				if (this.prenda.inversionista != 2) {
					this.form.montoConfirmacion = this.prenda.precio
				}
				if (this.prenda.inversionista == 2) {
					this.errorMontoConfirmacion = ''

					if(this.form.montoConfirmacion<this.montoMinimo){
						this.errorMontoConfirmacion = 'El monto debe ser mayor o igual a '
			                                                + this.$filters.currency(this.montoMinimo || 0);
						this.$refs.montoConfirmacion.$el.focus()
						return
					}
					
		            if (this.prenda.precio != this.form.montoConfirmacion) {
		            	if (this.prenda.montoPendiente == 0) {
		            		if (!this.form.montoConfirmacion ||  this.form.montoConfirmacion != this.prenda.precio) {
		            			this.errorMontoConfirmacion = 'El monto debe ser igual a '
			                                                + this.$filters.currency(this.prenda.precio || 0);
			                    this.$refs.montoConfirmacion.$el.focus()
			                    return
			                }
			              }else{
			              	if (!this.form.montoConfirmacion || this.form.montoConfirmacion <= 0 || this.form.montoConfirmacion > this.prenda.montoPendiente) {
			              		if(this.prenda.montoPendiente == this.montoMinimo){
			              			this.errorMontoConfirmacion = 'El monto debe ser igual a '+this.$filters.currency(this.montoMinimo);
				                    this.$refs.montoConfirmacion.$el.focus()
				                    return
			              		}else{
			              			this.errorMontoConfirmacion = 'El monto debe ser mayor o igual a '+this.$filters.currency(this.montoMinimo)+' y menor o igual a '
			                                                + this.$filters.currency(this.prenda.montoPendiente || 0);
				                    this.$refs.montoConfirmacion.$el.focus()
				                    return
			              		}
			                }
			            }
		            }
		        }
				this.checkValidity()
				if (!this.validateForm) {
					return
				}
				
				this.loadingPublicacion = true
				var params = {
					precio: this.form.montoConfirmacion,
		            fecha: Math.round(new Date().getTime()/1000.0),
		            idprenda: this.id,
		            idusuario: this.user.idusuario,
		            tasa: this.prenda.interes,
		            tiempopago: this.prenda.pago,
		            tasaPeriodo: 'Mensual',
		            tiempoPeriodo: 'Meses',
				}
				this.$store.dispatch('sendProposal', params).then(response => {
					window.toastr.info("Propuesta envíada exitosamente", "Éxito")
					this.mailsSendProposal(params)
					this.sendPush()
					this.redirectIndex = false
					window.$(this.$el).modal('hide')
					this.$router.push({name: 'perfil.mispropuestas.espera'})
					return response
				}).catch(error =>{
					window.toastr.error('Error al envíar propuesta', 'Error')
					return error
				}).then(() => {
					this.loadingPublicacion = false
				})

			},
			mailsSendProposal(prop){
				this.$store.dispatch('mailsSendProposal', {
					nomusuario: this.prenda.nomusuario,
	                apellido: this.prenda.apellido,
	                mail: this.prenda.idusuario,
	                nombrePrenda: this.prenda.nombre,
	                idprenda: this.id,
	                fotoPrenda: this.prenda.ruta,
	                nomusuarioInv: this.user.nombre,
	                apellidoInv: this.user.apellido,
	                montoOfertado: prop.precio,
	                tasa:prop.tasa,
	                tasaPeriodo: prop.tasaPeriodo,
	                tiempoPeriodo: prop.tiempoPeriodo,
	                tiempopago: prop.tiempopago,
	                fechaAprobado: Math.round(new Date().getTime()/1000.0)
				})
			},
			sendPush(){
				this.$store.dispatch('sendPush', {
					idusuario : this.prenda.idusuario, 
		        	title: '¡Tienes una nueva propuesta!', 
		        	message: 'Han realizado una propuesta para ' + this.prenda.nombre
				})
			},
			checkValidity(){
				if (!this.$refs.formPropuesta){
					this.validateForm = false
				}else{
					this.validateForm = this.$refs.formPropuesta.checkValidity()
				}
			},
			toIndex(){
				this.$router.push({name: 'opciones-inversion.show', params:{id: this.id}})
			},
		},
		computed: {
			id(){
				return this.$route.params.id || -1
			},
			publicacion(){
				return this.$store.getters.publicacion || []
			},
			prenda(){
				return this.publicacion[0] || {}
			},
			user(){
				return this.$store.getters.currentUser || {}
			},
			chartdata(){
				return {
					labels: ["Invertido", "Disponible"],
					datasets: [{
						label: "Pool",
						backgroundColor: ["#75c2a7", "#00a4ff"],
						data: [this.total, (this.prenda.montoPendiente || 0)]
	                }]
				}
			},
			chartoptions(){
				return {}
			},
			total(){
				return (this.prenda.precio || 0) - (this.prenda.montoPendiente || 0)
			},
			montoMinimo(){
				var monto = 3000//parseFloat(this.prenda.precio * (10.00/100.00)).toFixed(2)
				if(this.prenda.montoPendiente<monto){
					return this.prenda.montoPendiente
				}

				return monto
			}
		},
		watch:{
			'form':{
				handler(val){
					this.checkValidity()
					return val
				},
				deep: true
			}
		},
		beforeUnmount(){
			window.$('.modal-backdrop').remove()
		}
	}
</script>