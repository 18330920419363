<template>
	<div class="modal fade" id="propuesta" data-backdrop="static" role="dialog" aria-labelledby="propuestaLabel" aria-hidden="true">
		<div :class="{'modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable':true, 'modal-lg':prenda.inversionista == 2}" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<div class="d-flex justify-content-between w-100">
						<div>
							<h5 class="modal-title text-center w-100">
								Detalle de inversión
							</h5>
						</div>
						<div class="ml-2">
							<button type="button" class="btn btn-sm btn-link" data-dismiss="modal" aria-label="Close">
								<img src="@/assets/image/close.webp" class="img-fluid">
							</button>
						</div>
					</div>
				</div>
				<div class="modal-body">
					<table class="mb-3">
						<tr class="mb-2" v-if="prenda.inversionista == 2">
							<th>Capital:</th>
							<td class="pl-2">
								<select v-model="tipo" class="custom-select custom-select-sm bg-light">
									<option value="">Total ({{$filters.currency(prenda.precio)}})</option>
									<option value="disponible">Disponible ({{$filters.currency(prenda.montoPendiente)}})</option>
								</select>
							</td>
						</tr>
					</table>

					<div class="card bg-light">
						<div class="card-body">
							<div class="table-responsive">
								<table class="table table-sm">
									<thead>
										<tr class="text-center">
											<th width="50" align="center">Mes</th>
											<th align="center">Capital activo</th>
											<th align="center">Abono a capital</th>
											<th align="center">Ganancia mensual</th>
											<th align="center">Depósito a recibir</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(det, index) in detalles" :key="index">
											<th align="center">{{det.mes}}</th>
											<td align="center">{{$filters.currency(det.capital_activo)}}</td>
											<td align="center">{{$filters.currency(det.abono_a_capital)}}</td>
											<td align="center">{{$filters.currency(det.ganancia_mensual)}}</td>
											<td align="center">{{$filters.currency(det.deposito_a_recibir)}}</td>
										</tr>
										<tr>
											<th></th>
											<td align="center">Total</td>
											<td align="center">{{$filters.currency(amortizacion)}}</td>
											<th></th>
											<th></th>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer text-right">
		    		<button class="btn btn-light" @click="pdf">
						<BtnLoading v-if="loadingPdf" />
						<span v-else>
							<font-awesome-icon :icon="['fas', 'file-pdf']" class="text-danger" /> Descargar PDF
						</span>
					</button>

					<button class="btn btn-light" @click="excel" :disabled="loadingExcel">
						<BtnLoading v-if="loadingExcel" />
						<span v-else>
							<font-awesome-icon :icon="['fas', 'file-excel']" class="text-success" /> Descargar Excel
						</span>
					</button>
		    	</div>
		  	</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	const FileDownload = require('js-file-download')
	
	import { defineAsyncComponent } from 'vue'

	const BtnLoading = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "BtnLoading" */"@/components/BtnLoading.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const FontAwesomeIcon = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "fortawesome" */"@fortawesome/vue-fontawesome").then(mod => mod.FontAwesomeIcon),
		loadingComponent: {template:`<loading-component />`}
	})

	export default {
		components: {
			BtnLoading,
			FontAwesomeIcon
		},
		data(){
		  return {
		  	redirectIndex: true,
		  	tipo: '',
		  	formulario: false,
		  	loadingPdf: false,
		  	loadingExcel: false,
		  	formularioExcel: false,
		  }
		},
		mounted(){
			window.$(this.$el).modal('show')
			var vm = this
			window.$(this.$el).on('hidden.bs.modal', function () {
				if (vm.redirectIndex) {
					vm.toIndex()
				}
			})
		},
		methods: {
			toIndex(){
				this.$router.push({name: 'opciones-inversion.show', params:{id: this.id}})
			},

			cuota(saldo, interes, plazo){
				var d = ((saldo || 0) * (interes || 0))
				var e = (1-Math.pow((1+interes), ((plazo || 0)*-1)))
				return d/e
			},

			pdf() {
				this.loadingPdf = true
				this.$store.dispatch('downloadFile', {
					detalles: this.detallesExport,
					name: `${this.prenda.nombre || 'detalle'}.pdf`,
					url: '/apiv2/inversiones/pdf'
				}).then(res => {
					FileDownload(res.data, `${this.prenda.nombre || 'detalle'}.pdf`)
				}).catch(err => {
					return err
				}).then(() => {
					this.loadingPdf = false
				})
		    },

		    excel() {
		    	this.loadingExcel = true
				this.$store.dispatch('downloadFile', {
					detalles: this.detallesExport,
					name: `${this.prenda.nombre || 'detalle'}.xlsx`,
				}).then(res => {
					FileDownload(res.data, `${this.prenda.nombre || 'detalle'}.xlsx`)
				}).catch(err => {
					return err
				}).then(() => {
					this.loadingExcel = false
				})
		    }
		},
		computed: {
			id(){
				return this.$route.params.id || -1
			},
			publicacion(){
				return this.$store.getters.publicacion || []
			},
			prenda(){
				return this.publicacion[0] || {}
			},

			amortizacion(){
				return this.detalles.reduce((acc, item) => {
					return parseFloat(acc || 0) + parseFloat(item.abono_a_capital || 0)
				}, 0)
			},

			detalles(){
				var datos = []
				var saldo = this.total
				var amortizacion = 0
				var inversionista = 0
				var interesIva = ((this.prenda.interes || 0) * 1.12)/100

				var cuota = this.cuota(saldo, interesIva, this.plazo)
				
				for (var i = 1; i <= this.plazo; i++) {
					var intereses = (saldo * ((this.prenda.interes || 0)/100))
					var iva = (intereses || 0) * 0.12

					amortizacion = (cuota || 0) - (intereses + iva)
					inversionista = (intereses + iva) * (70/100)

					datos.push({
						mes: i,
						capital_activo: parseFloat(saldo).toFixed(2),
						abono_a_capital: parseFloat(amortizacion).toFixed(2),
						ganancia_mensual: parseFloat(inversionista).toFixed(2),
						deposito_a_recibir: parseFloat((amortizacion || 0) + (inversionista || 0)).toFixed(2)

					})

					saldo = saldo-amortizacion
				}

				return datos
			},

			detallesExport(){
				return this.detalles.map(x => {
					return {
						mes: x.mes,
						capital_activo: this.$filters.currency(x.capital_activo),
						abono_a_capital: this.$filters.currency(x.abono_a_capital),
						ganancia_mensual: this.$filters.currency(x.ganancia_mensual),
						deposito_a_recibir: this.$filters.currency(x.deposito_a_recibir)
					}
				}).concat([{
					mes: '',
					capital_activo: '',
					abono_a_capital: this.$filters.currency(this.amortizacion),
					ganancia_mensual: '',
					deposito_a_recibir: ''
				}])
			},

			plazo(){
				//return 24
				return this.prenda.pago || 0
			},
			user(){
				return this.$store.getters.currentUser || {}
			},
			total(){
				if(this.tipo=='disponible'){
					return this.prenda.montoPendiente || 0
				}

				return this.prenda.precio || 0
			}
		},
		beforeUnmount(){
			window.$('.modal-backdrop').remove()
		}
	}
</script>