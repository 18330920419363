<template>
	<div class="bg-white">
		<div class="container-lg py-5">
			<div class="row">
				<div class="col-lg-7 mb-3">
					<div :class="{'mb-5':!isMobile}">
						<img v-lazy="$filters.storage(fotoSeleccionada.ruta, 400)" class="img-fluid rounded-sm w-100" :alt="form.nombre">
						<div class="d-flex mt-3 align-items-stretch">
							<div class="cursor-pointer py-2 px-1" v-for="(foto, index) in fotos" v-on:click="selectFoto(foto)" :key="index">
								<img v-lazy="$filters.storage(foto.ruta, 100)" class="img-fluid h-100" alt="Foto" style="border-radius: 5px; max-height: 75px; object-fit: cover; object-position: center;">
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-5 mb-3">
					<div class="card cap-bg border shadow">
						<div class="card-body d-flex flex-column">
							<div class="mb-2">
								<input type="text" v-model="form.nombre" class="form-control border" placeholder="Título">
							</div>
							
							<div class="row">
								<div class="col-lg-6 mb-2">
									<p class="text-primary font-weight-bold mb-0">Monto de inversión:</p>
									<CurrencyInput type="text" class="form-control form-control-sm border" v-model="form.precio" />
								</div>

								<div class="col-lg-6 mb-2">
									<p class="text-primary font-weight-bold mb-0">Categoría:</p>
									<Categorias v-model="form.idcategoria"/>
								</div>
							</div>

							<div class="mb-4">
								<div class="mb-2">
									<div class="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center">
										<label class="font-weight-bold">Plazo:</label>
										<div class="ml-lg-3">
											<input type="number" class="form-control form-control-sm border" v-model="form.pago">
										</div>
									</div>
								</div>

								<div class="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center mb-2">
									<label class="font-weight-bold">Tasa de interés mensual:</label>
									<div class="ml-lg-3">
										<div class="input-group input-group-sm">
											<input type="number" class="form-control border" v-model="form.interes" min="0" max="100" aria-label="Tasa de interés mensual" aria-describedby="basic-addon2" step="0.5">
											<div class="input-group-append">
												<span class="input-group-text" id="basic-addon2">%</span>
											</div>
										</div>
									</div>
								</div>

								<div class="mb-2">
									<div class="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center">
										<label class="font-weight-bold">Tipo de cuota:</label>
										<div class="ml-lg-3">
											<input type="text" class="form-control form-control-sm border" v-model="form.tipoCuota">
										</div>
									</div>
								</div>

								<div class="mb-2">
									<div class="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center">
										<label class="font-weight-bold">Valor del carro:</label>
										<div class="ml-lg-3">
											<CurrencyInput type="text" class="form-control form-control-sm border" v-model="form.valorCarro" />
										</div>
									</div>
								</div>

								<div class="mb-1">
									<label class="font-weight-bold">Fecha firma de contratos:</label>
									<Date v-model="form.fechaContratos" />
								</div>

								<div class="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center mb-2">
									<label class="font-weight-bold">Tipo de desembolso:</label>
									<div class="ml-lg-3">
										<input type="text" class="form-control form-control-sm border" v-model="form.tipoDesembolso">
									</div>
								</div>

								<div class="custom-control custom-switch">
									<input type="checkbox" class="custom-control-input" id="notificarInversionista" v-model="form.notificarInversionista">
									<label class="custom-control-label" for="notificarInversionista">¿Notificar opción de inversión?</label>
								</div>
							</div>
							<div class="mt-auto" v-if="isLoggedIn && user.prf==3">
								<div class="d-flex justify-content-between mt-2">
									<button type="button" class="btn btn-inversion" v-on:click="saveHandler()" :disabled="loadingSave">
										<BtnLoading v-if="loadingSave" />
										<span v-else>
											<span class="material-icons align-bottom">save</span>
											Guardar
										</span>
									</button>
									<router-link :to="{name:'opciones-inversion.show', params:{id:id}}" class="btn btn-link rounded-pill w-50 font-weight-bold" :disabled="loadingSave">
										<BtnLoading v-if="loadingSave" />
										<span v-else>
											Cancelar
										</span>
									</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'

	const CurrencyInput = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Currency" */"@/components/Currency.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	const Date = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "formsDate" */"@/components/forms/Date.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const BtnLoading = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "BtnLoading" */"@/components/BtnLoading.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const Categorias = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "CatalogosCategorias" */"@/components/catalogos/Categorias.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	export default {
		components: {
			CurrencyInput,
			Date,
			Categorias,
			BtnLoading
		},
		data(){
			return {
				loadingPublicacion: false,
				loadingSave: false,
				
				form: {
					nombre: '',
					descripcion: '',
					precio: '',
					idusuario: '',
					idcategoria: '',
					tipo: '',
					interes: '',
					pago: '',
					tipoCuota: '',
					valorCarro: '',
					fechaContratos: '',
					tipoDesembolso: '',
					notificarInversionista: false
				},

				fotoSeleccionada: {}
			}
		},
		mounted(){
			this.getPublicacion()
			this.getPublicacionFotos()
		},
		methods: {
			saveHandler(){
				this.loadingSave = true
				this.form.fotos = (this.fotos || []).map(x => x.ruta)
				this.$store.dispatch('addPrendaPremium', this.form).then(response => {
					var data = this.prenda
					data.idprenda = response.data
					data.notificacion = this.form.notificarInversionista
					this.$store.dispatch('sendAprobacion', data)

					this.$router.push({name: 'opciones-inversion.index'})
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingSave = false
				})
			},
			getPublicacion(){
				this.loadingPublicacion = true
				this.$store.dispatch('getPublicacion',{
					idprenda: this.id,
					idusuario: this.user.idusuario || 0
				}).then(response => {
					var prendas = response.data || []
					var prenda = prendas[0] || {}

					this.form.nombre = prenda.nombre
					this.form.descripcion = prenda.descripcion
					this.form.precio = prenda.precio
					this.form.idusuario = prenda.idusuario
					this.form.idcategoria = prenda.idcategoria
					this.form.idtipo = prenda.idtipo
					this.form.interes = prenda.interes
					this.form.pago = prenda.pago
					this.form.tipoCuota = prenda.tipoCuota
					this.form.valorCarro = prenda.valorCarro
					this.form.fechaContratos = prenda.fechaContratos
					this.form.tipoDesembolso = prenda.tipoDesembolso

					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingPublicacion = false
				})
			},
			getPublicacionFotos(){
				this.loadingPublicacion = true
				this.$store.dispatch('getPublicacionFotos',{
					idprenda: this.id
				}).then(response => {
					var fotos = response.data
					this.fotoSeleccionada = fotos[0] || {}
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingPublicacion = false
				})
			},
			selectFoto(val){
				this.fotoSeleccionada = val
			}
		},
		computed: {
			id(){
				return this.$route.params.id || -1
			},
			isLoggedIn(){
				return this.$store.getters.isLoggedIn
			},
			user(){
				return this.$store.getters.currentUser || {}
			},
			isMobile() {
				return this.$store.getters.isMobile
			},
			publicacion(){
				return this.$store.getters.publicacion || []
			},
			prenda(){
				return this.publicacion[0] || {}
			},
			fotos(){
				return this.$store.getters.publicacionFotos || []
			},
		}
	}
</script>
