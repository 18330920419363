<template>
	<div class="bg-white">
		<div class="container-fluid">
			<div class="row">
		      <div class="col-lg-12 position-relative px-0 carousel-control-hidden" style=" height: 300px;">
		        <img src="@/assets/image/opciones-inversion-portada.webp" alt="portada solicitante" class="position-absolute" style="left: 0px; right: 0px; top:0px; bottom: 0px; width: 100%; height: 100%; object-fit: cover;">
		      </div>
		    </div>

			<div class="row pt-3 justify-content-center">
				<div class="col-lg-12 mb-3">
					<h1 class="display-3 font-weight-bold text-center">Rendimientos transparentes y accesibles en préstamos P2P</h1>
				</div>
			</div>
			
			<div class="row py-3 justify-content-center">
				<div class="col-lg-11 my-5">
					<Swipper></Swipper>
				</div>

				<div class="col-lg-8 mb-3">
					<h2 class="h1 font-weight-bold text-center">Elige el tipo de inversión que deseas realizar</h2>
				</div>
			</div>

			<div class="row justify-content-center">
				<div class="col-lg-10 mb-3">
					<div class="d-flex flex-column flex-lg-row justify-content-center">
						<div class="mb-3 mr-lg-3" v-if="categorias.length>0">
							<button :class="{'btn btn-secondary btn-lg px-3':true, 'w-100':isMobile}" type="button" v-on:click="toggleFilter('categorias')">
								<div class="d-flex justify-content-between">
									<span>
										Categorías <span v-if="filters.categoriasfilter.length>0">({{filters.categoriasfilter.length}})</span>
									</span>
								 	<div class="ml-3">
								 		<img src="@/assets/image/down_arrow_white.webp" alt="flecha" :class="{'mr-3':true, 'rotate-180':showFilter == 'categorias'}" height="15">
								 	</div>
								</div>
							</button>
						</div>

						<div class="mb-3 mr-lg-3">
							<button :class="{'btn btn-secondary btn-lg px-3':true, 'w-100':isMobile}" type="button" v-on:click="toggleFilter('monto')">
								<div class="d-flex justify-content-between">
									<span>
										Monto (Q.)
									</span>
								 	<div class="ml-3">
								 		<img src="@/assets/image/down_arrow_white.webp" alt="flecha" :class="{'mr-3':true, 'rotate-180':showFilter == 'monto'}" height="15">
								 	</div>
								</div>
							</button>
						</div>

						<div class="mb-3">
							<button type="button" :class="{'btn btn-warning btn-lg px-3 text-white':true, 'w-100':isMobile}" v-on:click="filterPremiumHandler()">
								Inversión Premium
							</button>
						</div>
					</div>

					<div v-if="showFilter=='categorias'" class="row justify-content-center align-items-center">
						<div class="col-lg-11 mb-3">
							<div class="card bg-light">
								<div class="card-body">
									<div class="d-flex flex-column flex-lg-row justify-content-center">
										<div v-for="(categoria, index) in categorias" :key="index" class="mb-3">
											<div class="custom-control custom-switch">
												<input type="checkbox" class="custom-control-input" :id="'categoria'+index" :value="categoria.id" v-model="filters.categoriasfilter" v-on:change="loadData()">
												<label class="custom-control-label" :for="'categoria'+index">
													<span v-if="false">
														<i class="material-icons mr-2" v-if="categoria.id==16">storefront</i>
														<i class="material-icons mr-2" v-else>{{materialIcons[categoria.icon]}}</i>
													</span>
													<img v-lazy="categoria.icon" v-if="categoria.icon" height="50" width="50" style="object-fit: contain;">
													<br>
													{{categoria.nombre}}
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div v-if="showFilter=='monto'" class="row justify-content-center align-items-center">
						<div class="col-lg-10 mb-3">
							<div class="card bg-light">
								<div class="card-body">
									<div :class="{'border-bottom border-primary':true}">
										<h5 class="font-weight-bold">Selecciona valor (Q.)</h5>
									</div>

									<div>
										<div v-if="filters.precio[0]>= 0 && filters.precio[1]>=0" class="mb-3">
											<p class="text-primary font-weight-bold mb-0">Precio (Q)</p>
											<div class="form-row mb-2">
												<div class="col-6">
													<CurrencyInput v-model="filters.precio[0]" type="text" placeholder="desde" class="form-control border" :min="precio.min" />
												</div>
												<div class="col-6">
													<CurrencyInput v-model="filters.precio[1]" type="text" placeholder="hasta" class="form-control border" :min="precio.min" />
												</div>
											</div>
											<Slider v-model="filters.precio" :min="precio.min" :max="10000000" :tooltips="false" />
										</div>
									</div>

									<div class="d-flex align-items-lg-center justify-content-center" v-if="false">
										<div class="mb-3 mr-lg-3">
											<label class="mb-0">min:</label>
											<div class="currency-input position-relative">
												<span class='currency-symbol'>Q.</span>
												<CurrencyInput type="text" v-model="filters.min" :symbol="''" class="form-control currency" />
												<span style="right: 2px;bottom: 0;" class="currency-symbol d-flex flex-column">
													<button style="height: 13px;font-size: 10px;line-height: 4px;display: block;width: 10px;text-align: center;background-color: transparent;border: 0px;padding: 0px;" v-on:click="amountMin()">
													<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 24 24"><path d="M12 3l12 18h-24z" fill="#00a4ff"/></svg>
													</button>
													<button style="height: 13px;font-size: 10px; line-height: 4px; top: -16px;width: 10px;text-align: center;background-color: transparent;border: 0px;padding: 0px;" v-on:click="amountMin(false)">
													<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 24 24"><path d="M12 21l-12-18h24z" fill="#00a4ff"/></svg>
													</button>
												</span>
											</div>
										</div>
										<div class="mb-3">
											<label class="mb-0">max:</label>
											<div class="currency-input position-relative">
												<span class='currency-symbol'>Q.</span>
												<CurrencyInput type="text" v-model="filters.max" :symbol="''" class="form-control currency" />
												<span style="right: 2px;bottom: 0;" class="currency-symbol d-flex flex-column ">
													<button style="height: 13px;font-size: 10px;line-height: 4px;display: block;width: 10px;text-align: center;background-color: transparent;border: 0px;padding:0px;" v-on:click="amountMax()">
													<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 24 24"><path d="M12 3l12 18h-24z" fill="#00a4ff"/></svg>
													</button>
													<button style="height: 13px;font-size: 10px; line-height: 4px; top: -16px;width: 10px;text-align: center;background-color: transparent;border: 0px;padding: 0px;" v-on:click="amountMax(false)">
													<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 24 24"><path d="M12 21l-12-18h24z" fill="#00a4ff"/></svg>
													</button>
												</span>
											</div>
										</div>
									</div>


								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="row justify-content-center" v-if="publicaciones.length<=0">
				<div class="col-lg-10 mb-3">
					<div class="alert alert-primary alert-dismissible fade show px-3 text-justify" role="alert">
						Por el momento no tenemos opciones de inversión disponibles. Crea un usuario y espera la notificación de nuevas oportunidades. Recuerda, los inversionistas siempre están alertas y se encargan de que las oportunidades de inversión no duren más de 24 horas disponibles en la plataforma.
					</div>
				</div>
			</div>

			<div class="row py-5 justify-content-center">
				<div class="col-lg-9 mb-3" v-if="!isLoggedIn">
					<div class="alert alert-primary alert-dismissible fade show" role="alert">
						Recuerda para poder realizar una inversion debes estar <strong><router-link :to="{name:'register'}">registrado</router-link></strong> o <strong><router-link :to="{name:'login'}">iniciar sesion</router-link></strong>
						<button type="button" class="close" data-dismiss="alert" aria-label="Close">
						<span aria-hidden="true">&times;</span>
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="container-lg">
			<div>
				<div class="mb-3" v-if="publicaciones.length>0">
					<div class="form-row">
						<template v-for="(prenda, index) in publicaciones" :key="index">
							<div class="col-lg-12 mb-3" v-if="pagination.current_page==1 && index==3" >
								<OpcionInversionProximamente />
							</div>
							<OpcionInversionCard class="col-lg-4 mb-3" :data="prenda" />
						</template>
					</div>
					<div class="py-5 d-flex justify-content-center">
						<Paginacion :pagination="pagination" @paginate="changePagination" :offset="4" />
					</div>
				</div>
				<div class="mb-3" v-else>
					<OpcionInversionProximamente />
				</div>
			</div>

			<OpcionInversionTerminado />
		</div>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'
	import OpcionInversionProximamente from "@/components/opciones-inversion/Proximamente.vue"
	import OpcionInversionTerminado from '@/components/opciones-inversion/Terminadas.vue'
	import Swipper from "@/components/opciones-inversion/Swipper.vue"
	
	const Paginacion = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Pagination" */"@/components/Pagination.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const CurrencyInput = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Currency" */"@/components/Currency.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const Slider = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Slider" */"@vueform/slider"),
		loadingComponent: {template:`<loading-component />`}
	})

	const OpcionInversionCard = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Opciones-inversionCard" */"@/components/opciones-inversion/Card.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	export default {
		components: {
			CurrencyInput,
			Paginacion,
			OpcionInversionCard,
			OpcionInversionProximamente,
			OpcionInversionTerminado,
			Swipper,
			Slider
		},
		data(){
			return {
				showFilter: '',
				loadingCategorias: false,
				loadingTipos: false,
				loadingPublicaciones: false,
				
				
				loadingPublicacionesCount: false,
				publicaciones: [],
				
				filters: {
					categoriasfilter: [],
					tiposfilter: [],
					estado: [1],
					precio: [0, 10000000],
					premium: 0
				},
				pagination: {
					per_page: 6,
		            current_page: 1,
		            total: 50
		        },

		        precio: {
					min: 0,
					max: 10000000
				},

				showCategories: false,
				showAllFilters: true,
				materialIcons: {
					'ion-android-pin': 'apartment',
				    'ion-ios-box': 'home_work',
				    'ion-ios-home': 'house',
				    'ion-android-bicycle': 'two_wheeler',
				    'ion-ios-briefcase': 'business',
				    'ion-ios-time': 'watch',
				    'ion-map': 'nature_people',
				    'ion-model-s': 'directions_car',
				    'inversion-colectiva': 'group'
				},
			}
		},
		mounted(){
			this.getCategorias()
			this.getTipos()
			this.getPublicacionCount()
			this.getPublicacionesDisp()
		},
		methods:{
			filterPremiumHandler(){
				if(this.filters.premium == 1){
					this.filters.premium = 0
				} else {
					this.filters.premium = 1
				}

				this.getPublicacionCount()
				this.getPublicacionesDisp()
			},

			toggleFilter(index){
				if(this.showFilter == index){
					this.showFilter = ''
				}else{
					this.showFilter = index
				}
			},

			getCategorias(){
				this.loadingCategorias = true
				this.$store.dispatch('getCategorias').then(response => {
					var data = response.data || []
					this.filters.categoriasfilter = data.map(x => x.id)
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingCategorias = false
				})
			},
			getTipos(){
				this.loadingTipos = true
				this.$store.dispatch('getTipos').then(response => {
					var data = response.data || []
					this.filters.tiposfilter = data.map(x => x.id)
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingTipos = false
				})
			},
			getPublicacionesDisp(validatePage = false){
				this.loadingPublicaciones = true
				if (validatePage) {
					this.pagination.current_page = 1
				}
				this.$store.dispatch('getPublicacionesDisp',{
					categoriasfilter: this.filters.categoriasfilter,
					tiposfilter: this.filters.tiposfilter,
					min: this.filters.precio[0],
					max: this.filters.precio[1],
					estado: this.filters.estado,
					premium: this.filters.premium,
					limit: this.pagination.per_page,
					offset: ((this.pagination.current_page*this.pagination.per_page)-this.pagination.per_page)
				}).then(response => {
					this.publicaciones = response.data || []
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingPublicaciones = false
				})
			},
			getPublicacionCount(){
				this.loadingPublicacionesCount = true
				this.$store.dispatch('getPublicacionCount', {
					categoriasfilter: this.filters.categoriasfilter,
					tiposfilter: this.filters.tiposfilter,
					min: this.filters.precio[0],
					max: this.filters.precio[1],
					estado: this.filters.estado,
					premium: this.filters.premium,
					limit: this.pagination.per_page,
					offset: ((this.pagination.current_page*this.pagination.per_page)-this.pagination.per_page)
				}).then(response => {
					var data = response.data || []
					data = data[0] || {}
					this.pagination.total = data.total || 0
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingPublicacionesCount = false
				})
			},
			loadData(){
				if (this.filters.tiposfilter.length<=0) {
					this.filters.tiposfilter = [-1]
				}
				if (this.filters.categoriasfilter.length<=0) {
					this.filters.categoriasfilter = [-1]
				}
				if (this.filters.estado.length<=0) {
					this.filters.estado = [-1]
				}

				this.filters.premium = 0

				this.getPublicacionesDisp(true)
				this.getPublicacionCount()
			},
			filtersMobile(val){
				this.showCategories	= val
				this.showAllFilters = val
			},
			filtersDesktop(val){
				this.showCategories	= val
				this.showAllFilters = true
			},
			changePagination(page){
				this.pagination.current_page = page
				this.getPublicacionesDisp()
			},
			amountMax(increment=true){
				if (increment) {
					this.filters.precio[1]++
				}else{
					if (this.filters.precio[1]<=0) {return}
					this.filters.precio[1]--
				}
				this.loadData()
			},
			amountMin(increment=true){
				if (increment) {
					this.filters.precio[0]++
				}else{
					if (this.filters.precio[0]<=0) {return}
					this.filters.precio[0]--
				}
				this.loadData()
			},
			porcentajePrenda(prenda){
				return this.trunc(((prenda.montoRecaudado*100)/prenda.precio), 2)
			},
			trunc(x, posiciones = 0) {
				var s = x.toString()
				var l = s.length
				var decimalLength = s.indexOf('.') + 1

				if (l - decimalLength <= posiciones){
				  return x
				}
				// Parte decimal del número
				var isNeg  = x < 0
				var decimal =  x % 1
				var entera  = isNeg ? Math.ceil(x) : Math.floor(x)
				// Parte decimal como número entero
				// Ejemplo: parte decimal = 0.77
				// decimalFormated = 0.77 * (10^posiciones)
				// si posiciones es 2 ==> 0.77 * 100
				// si posiciones es 3 ==> 0.77 * 1000
				var decimalFormated = Math.floor(
				  Math.abs(decimal) * Math.pow(10, posiciones)
				)
				// Sustraemos del número original la parte decimal
				// y le sumamos la parte decimal que hemos formateado
				var finalNum = entera + 
				  ((decimalFormated / Math.pow(10, posiciones))*(isNeg ? -1 : 1))
				return finalNum
			}
		},
		computed:{
			isMobile() {
				return this.$store.getters.isMobile
			},
			categorias(){
				return this.$store.getters.categorias || []
			},
			tipos(){
				return this.$store.getters.tipos || []
			},
			isLoggedIn(){
				return this.$store.getters.isLoggedIn 
			},
			filtersMin(){
				return this.filters.precio[0] || 0
			},
			filtersMax(){
				return this.filters.precio[1] || 0
			}
		},
		watch: {
			'filtersMax': function(val){
				if (val<0) {
					this.filters.precio[1] = 0
				}
				this.loadData()
			},
			'filtersMin': function(val){
				if (val<0) {
					this.filters.precio[0] = 0
				}
				this.loadData()
			}
		}
	}	
</script>