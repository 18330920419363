<template>
	<div>
		<Carousel :items-to-show="items" :wrap-around="true" :autoplay="5000" v-if="datosFilter.length>0">
			<Slide v-for="(info, index) in datosFilter" :key="index">
				<div class="carousel__item">
					<div class="card h-100 border border-primary overflow-hidden">
						<div class="card-body px-1 text-center">
							<div class="mb-1">
								<img v-lazy="info.image" height="20">
							</div>
							<h4 class="font-weight-bold" v-html="info.titulo"></h4>
						</div>
						<div class="card-body bg-inversion" style="min-height: 150px;">
							<div class="text-white" v-html="info.descripcion"></div>
						</div>
					</div>
				</div>
			</Slide>

			<template #addons>
				<Navigation />
			</template>
		</Carousel>
	</div>
</template>

<script>
import AutoCompras from '@/assets/image/auto-compras-icono.webp'
import Vehiculos from '@/assets/image/sobre-vehiculo.webp'

import { defineComponent, defineAsyncComponent } from 'vue'

const Carousel = defineAsyncComponent({
	loader: () => import(/* webpackChunkName: "CarouselV3" */"vue3-carousel").then(mod => mod.Carousel),
	loadingComponent: {template:`<loading-component />`}
})

const Navigation = defineAsyncComponent({
	loader: () => import(/* webpackChunkName: "CarouselV3" */"vue3-carousel").then(mod => mod.Navigation),
	loadingComponent: {template:`<loading-component />`}
})

const Slide = defineAsyncComponent({
	loader: () => import(/* webpackChunkName: "CarouselV3" */"vue3-carousel").then(mod => mod.Slide),
	loadingComponent: {template:`<loading-component />`}
})

export default defineComponent({
	name: 'WrapAround',
	data(){
		return {
			datos: [
				{
					image: AutoCompras,
					titulo: 'INVERSIÓN <br> préstamo AutoCompras',
					descripcion: '<ul class="list-unstyled"><li>Rendimiento desde 14.11% anual </li><li>Plazo de inversión promedio Hasta 60 meses</li></ul>'
				},
				{
					image: Vehiculos,
					titulo: 'INVERSIÓN <br> préstamo sobre carro',
					descripcion: '<ul class="list-unstyled"><li>Rendimiento desde 14.11% anual </li><li>Plazo de inversión promedio Hasta 60 meses</li></ul>'
				}
			]
		}
	},
	components: {
		Carousel,
		Slide,
		Navigation
	},
	computed: {
		items(){
			if(this.isMobile){
				return 1.95
			}

			return 4.95
		},
		isMobile() {
			return this.$store.getters.isMobile
		},
		datosFilter(){
			return this.datos.concat(this.datos)
		}
	}
})
</script>