<template>
	<div class="p-5" v-if="publicaciones.length>0">
		<h1 class="h1 mb-3 text-center font-weight-bold">
			Inversiones cerradas con éxito
		</h1>

		<Carousel :items-to-show="items" :snapAlign="'start'">
			<Slide v-for="(publicacion, index) in publicacionesFilter" :key="index">
				<OpcionInversionCard class="w-100 h-100 carousel__item" :data="publicacion" />
			</Slide>

			<template #addons>
				<Pagination v-if="!isMobile" />
				<Navigation />
			</template>
		</Carousel>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'

	const OpcionInversionCard = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Opciones-inversionCard" */"@/components/opciones-inversion/Card.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const Carousel = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "CarouselV3" */"vue3-carousel").then(mod => mod.Carousel),
		loadingComponent: {template:`<loading-component />`}
	})

	const Navigation = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "CarouselV3" */"vue3-carousel").then(mod => mod.Navigation),
		loadingComponent: {template:`<loading-component />`}
	})

	const Slide = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "CarouselV3" */"vue3-carousel").then(mod => mod.Slide),
		loadingComponent: {template:`<loading-component />`}
	})

	const Pagination = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "CarouselV3" */"vue3-carousel").then(mod => mod.Pagination),
		loadingComponent: {template:`<loading-component />`}
	})

	export default {
		components: {
			OpcionInversionCard,
			Carousel,
			Slide,
			Navigation,
			Pagination
		},
		data(){
			return {
				loadingPublicaciones: false,
				publicaciones: []
			}
		},
		mounted(){
			this.getPublicaciones()
		},

		methods: {
			getPublicaciones(){
				this.loadingPublicaciones = true
				this.$store.dispatch('getPublicacionesDisp',{
					estado: [5],
					limit: 20,
					offset: ((1*20)-20)
				}).then(response => {
					this.publicaciones = response.data || []
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingPublicaciones = false
				})
			},
		},

		computed: {
			items(){
				if (this.isMobile) {
					return 1
				}
				return 4
			},
			publicacionesFilter(){
				return this.publicaciones || []
			},
			isMobile() {
				return this.$store.getters.isMobile
			},
		}

	}
</script>