<template>
	<div class="bg-white">
		<div class="container-lg py-5">
			<div class="row">
				<div class="col-lg-7 mb-3" v-if="prenda.idtipo == 12">
					<div class="p-5 d-flex align-items-center justify-content-center">
						<img v-lazy="prenda.icon" class="img-fluid">
					</div>
				</div>
				<div class="col-lg-7 mb-3" v-else>
					<div :class="{'mb-5':!isMobile}">
						<img v-lazy="$filters.storage(fotoSeleccionada.ruta, 400)" class="img-fluid rounded-sm w-100" :alt="prenda.nombre">
						<div class="d-flex mt-3 align-items-stretch">
							<div class="cursor-pointer py-2 px-1" v-for="(foto, index) in fotos" v-on:click="selectFoto(foto)" :key="index">
								<img v-lazy="$filters.storage(foto.ruta, 100)" class="img-fluid h-100" alt="Foto" style="border-radius: 5px; max-height: 75px; object-fit: cover; object-position: center;">
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-5 mb-3">
					<div class="card cap-bg border shadow">
						<div class="card-body d-flex flex-column">
							<div class="d-flex align-items-center justify-content-end">
								<div class="mr-1">
									<div class="dropdown">
										<button class="btn btn-sm btn-link" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Compartir">
											<span class="material-icons text-primary vertical-middle">share</span>
										</button>
										<ul class="dropdown-menu dropdown-menu-right overflow-hidden" aria-labelledby="dropdownMenuButton">
											<li class="dropdown-item" v-for="network in networks" :key="network.network">
												<ShareSocial
													:network="network.network"
													:key="network.network"
													:class="network.class"
													:url="sharing.url"
													:title="sharing.title"
													:description="sharing.description"
													:quote="sharing.quote"
													:hashtags="sharing.hashtags"
													:twitterUser="sharing.twitterUser">
													{{network.nombre}}
												</ShareSocial>
											</li>
											<li class="dropdown-item">
												<a href="#" v-on:click.prevent="print()">
													Imprimir
												</a>
											</li>
											<li class="dropdown-item">
												<a href="#" v-on:click.prevent="copy()">
													Copiar enlace
												</a>
											</li>
										</ul>
									</div>
								</div>

								<div class="mr-1">
									<button type="button" class="btn btn-sm btn-link text-danger border-0" title="Quitar de favoritos" v-on:click="prendaDeleteFavorite()" v-if="prenda.favorito && prenda.est != 5">
										<i class="material-icons vertical-middle">favorite</i>
									</button>
									<button type="button" class="btn btn-sm btn-link mr-2" title="Añadir a favoritos" v-on:click="prendaAddFavorite()" v-if="!prenda.favorito && prenda.est != 5">
										<i class="material-icons vertical-middle">favorite_border</i>
									</button>
								</div>
								<div>
									<router-link :to="{name:'opciones-inversion.index'}" class="btn btn-sm btn-link">
										<img src="@/assets/image/close.webp" class="img-fluid">
									</router-link>
								</div>
							</div>

							<div v-if="editarPrenda" class="mb-2">
								<input type="text" v-model="form.nombre" class="form-control border" placeholder="Título">
							</div>
							<div v-else>
								<h1 class="border-left border-secondary mb-3 pl-3 font-weight-bold" style="font-size: 25px; border-width: 7px !important;" v-if="prenda.idtipo == 12">{{prenda.categoria}}</h1>
								<h1 class="border-left border-secondary mb-3 pl-3 font-weight-bold" style="font-size: 25px; border-width: 7px !important;" v-else>{{prenda.nombre}}</h1>
							</div>

							<div class="row" v-if="editarPrenda">
								<div class="col-lg-6 mb-2">
									<p class="text-primary font-weight-bold mb-0">Monto de inversión:</p>
									<CurrencyInput type="text" class="form-control form-control-sm border" v-model="form.precio" />
								</div>

								<div class="col-lg-6 mb-2">
									<p class="text-primary font-weight-bold mb-0">Categoría:</p>
									<Categorias v-model="form.idcategoria"/>
								</div>
							</div>

							<div class="d-flex justify-content-between mb-4" v-else>
								<div>
									<p class="h4 text-primary font-weight-bold mb-0">Monto de inversión:</p>
									<h3 class="font-weight-bold mb-2">{{$filters.currency(prenda.precio)}}</h3>
								</div>
								<div class="ml-3" v-if="!editarPrenda">
									<div v-if="!(prenda.idtipo == 12)">
										<p class="h4 text-primary font-weight-bold mb-0">Categoría:</p>
										{{prenda.categoria}}
									</div>
								</div>
							</div>

							<div class="mb-4">
								<div class="mb-2">
									<div class="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center" v-if="editarPrenda">
										<label class="font-weight-bold">Plazo:</label>
										<div class="ml-lg-3">
											<input type="number" class="form-control form-control-sm border" v-model="form.pago">
										</div>
									</div>
									<div v-else>
										<label class="font-weight-bold">Plazo:</label> {{prenda.pago}}
									</div>
								</div>

								<div class="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center mb-2" v-if="editarPrenda">
									<label class="font-weight-bold">Tasa de interés mensual:</label>
									<div class="ml-lg-3">
										<div class="input-group input-group-sm">
											<input type="number" class="form-control border" v-model="form.interes" min="0" max="100" aria-label="Rendimiento anual para ti" aria-describedby="basic-addon2" step="0.5">
											<div class="input-group-append">
												<span class="input-group-text" id="basic-addon2">%</span>
											</div>
										</div>
									</div>
								</div>

								<div class="mb-2" v-if="rendimiento>0">
									<label class="font-weight-bold">Rendimiento anual para tí:</label> {{$filters.currency(rendimiento, false)}}%
								</div>

								<div class="mb-2">
									<div class="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center" v-if="editarPrenda">
										<label class="font-weight-bold">Tipo de cuota:</label>
										<div class="ml-lg-3">
											<input type="text" class="form-control form-control-sm border" v-model="form.tipoCuota">
										</div>
									</div>
									<div v-else>
										<label class="font-weight-bold">Tipo de cuota:</label> {{prenda.tipoCuota || ''}}
									</div>
								</div>

								<div class="mb-2">
									<div class="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center" v-if="editarPrenda">
										<label class="font-weight-bold">Valor del carro:</label>
										<div class="ml-lg-3">
											<CurrencyInput type="text" class="form-control form-control-sm border" v-model="form.valorCarro" />
										</div>
									</div>
									<div v-else>
										<div v-if="prenda.valorCarro">
											<label class="font-weight-bold">Valor del carro:</label> {{$filters.currency(prenda.valorCarro)}}
										</div>
									</div>
								</div>

								<div class="mb-1" v-if="editarPrenda">
									<label class="font-weight-bold">Fecha firma de contratos:</label>
									<InputDate v-model="form.fechaContratos" />
								</div>

								<div class="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center mb-2" v-if="editarPrenda">
									<label class="font-weight-bold">Tipo de desembolso:</label>
									<div class="ml-lg-3">
										<input type="text" class="form-control form-control-sm border" v-model="form.tipoDesembolso">
									</div>
								</div>

								<div class="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center mb-2" v-if="editarPrenda">
									<label class="font-weight-bold">Inversionista:</label>
									<div class="ml-lg-3">
										<select class="custom-select" v-model="form.inversionista">
											<option value="1">Un inversionista</option>
											<option value="2">Pool de inversionista</option>
										</select>
									</div>
								</div>

								<div class="mb-2" v-if="editarPrenda">
									<label class="font-weight-bold">Fecha publicación:</label>
									<InputDate v-model="form.epoch" />
								</div>

								<div class="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center mb-2" v-if="editarPrenda">
									<label class="font-weight-bold">Estado:</label>
									<div class="ml-lg-3">
										<select class="custom-select" v-model="form.estado">
											<option value="0">En espera de autorización</option>
											<option value="1">Autorizado</option>
											<option value="2">Denegado</option>
											<option value="3">Finalizado</option>
										</select>
									</div>
								</div>

								<div class="custom-control custom-switch" v-if="form.estado==1 && editarPrenda">
									<input type="checkbox" class="custom-control-input" id="notificarInversionista" v-model="form.notificarInversionista">
									<label class="custom-control-label" for="notificarInversionista">¿Notificar opción de inversión?</label>
								</div>

								<Pool v-if="prenda.inversionista == 2" />
								<div class="progress bg-secondary-light mb-3" style="height: 24px; font-size: 1rem;" v-if="prenda.inversionista == 2" :title="$filters.currency(prenda.montoPendiente)">
									<div :class="{'progress-bar progress-bar-striped progress-bar-animated font-weight-bold':true, 'bg-secondary':prenda.estado=='Autorizado', 'bg-gray':prenda.estado!='Autorizado'}" role="progressbar" :style="{width: porcentajePrenda+'%'}" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" :title="$filters.currency(prenda.montoRecaudado)">
										{{porcentajePrenda}}%
									</div>
								</div>
								<div class="row mb-3 border rounded" v-if="prenda.inversionista==2">
									<div class="col-6 text-center border-right">
										<small>{{$filters.currency(prenda.montoRecaudado)}}</small>
										<br>
										<small>
										<strong>
										Recaudado
										</strong>
										</small>
									</div>
									<div class="col-6 text-center">
										<small>{{prenda.inversores}}</small>
										<br>
										<small>
										<strong>
										inversionistas
										</strong>
										</small>
									</div>
								</div>
							</div>
							<div class="mt-auto">
								<div v-if="!editarPrenda">
									<div class="d-flex align-items-center justify-content-between" v-if="!(prenda.idtipo==12)">
										<div class="mr-2" v-if="prenda.est!=5">
											<router-link :to="{name:'opciones-inversion.detalle', params:{id: codigo}}" class="btn btn-primary rounded-pill font-weight-bold px-3" v-if="isLoggedIn">
												Detalle de inversión
											</router-link>
										</div>
										<div v-if="prenda.est!=5">
											<div v-if="prenda.idVehiculo">
												<router-link :to="{name:'opciones-inversion.propuesta', params:{id: codigo}}" class="btn btn-inversion rounded-pill font-weight-bold px-3" v-if="isLoggedIn && prenda.proposal <= 0 && user.idusuario != prenda.idusuario && prenda.pago && prenda.interes">
													Realizar propuesta
												</router-link>
											</div>
											<div v-else>
												<router-link :to="{name:'opciones-inversion.propuesta', params:{id: codigo}}" class="btn btn-inversion rounded-pill font-weight-bold px-3" v-if="isLoggedIn && prenda.proposal <= 0 && user.idusuario != prenda.idusuario">
													Realizar propuesta
												</router-link>
											</div>
											<router-link :to="{name:'perfil.mispropuestas.espera'}" class="btn btn-inversion rounded-pill font-weight-bold px-3" v-if="isLoggedIn && prenda.proposal > 0">
												Ver propuesta realizada.
											</router-link>
										</div>
									</div>
								</div>
								<div class="alert alert-primary alert-dismissible fade show" role="alert" v-if="!isLoggedIn">
									Recuerda para poder realizar una inversion debes estar <strong><router-link :to="{name:'register'}">registrado</router-link></strong> o <strong><router-link :to="{name:'login'}">iniciar sesion</router-link></strong>
									<button type="button" class="close" data-dismiss="alert" aria-label="Close">
									<span aria-hidden="true">&times;</span>
									</button>
								</div>

								<div class="d-flex align-items-center justify-content-between" v-if="!(prenda.idtipo==12)">
									<div v-if="isLoggedIn && user.prf==3" class="mt-2">
										<button type="button" class="btn btn-secondary" v-on:click="editarHandler()" v-if="!editarPrenda">
											Editar
										</button>
									</div>
									<div v-if="isLoggedIn && user.prf==3 && !editarPrenda" class="mt-2">
										<router-link :to="{name:'opciones-inversion.premium', params:{id: codigo}}" class="btn btn-warning text-white rounded-pill font-weight-bold px-3">
												Convertir en premium
										</router-link>
									</div>
								</div>
								<div class="d-flex align-items-center justify-content-between" v-if="!(prenda.idtipo==12)">
									<button type="button" class="btn btn-inversion" v-on:click="saveHandler()" v-if="editarPrenda" :disabled="loadingSave">
										<BtnLoading v-if="loadingSave" />
										<span v-else>
											<span class="material-icons align-bottom">save</span>
											Actualizar
										</span>
									</button>
									<button type="button" v-on:click="editarHandler(false)" class="btn btn-link rounded-pill w-50 font-weight-bold" :disabled="loadingSave" v-if="editarPrenda">
										<BtnLoading v-if="loadingSave" />
										<span v-else>
											Cancelar
										</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-lg-12 mb-3" v-if="user.prf == 3">
					<div class="card bg-light">
						<div class="card-body">
							<div class="d-flex">
								<div class="mr-3">
									<img v-lazy="prenda.foto" class="w-auto" height="50">
								</div>
								<div>
									<div>
										<label class="font-weight-bold">Nombres:</label>
										{{prenda.nomusuario}} {{prenda.apellido}}
									</div>

									<div>
										<label class="font-weight-bold">DPI:</label>
										{{prenda.dpi}}
									</div>

									<div>
										<label class="font-weight-bold">Alias:</label>
										{{prenda.aliasusuario}}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-lg-12 mb-3">
					<div class="row">
						<div class="col-lg-12 mb-5 mb-lg-3">
							<div class="row">
								<div class="col-lg-6">
									<h2 class="mb-2 mb-lg-4 font-weight-bold">¿Qué documentos necesitas para poder invertir?</h2>
								</div>
								<div class="col-lg-6">
									<div class="accordion" id="preguntasAccordion">
										<div id="headingOne">
											<h2 class="mb-0">
												<button class="btn btn-block text-left d-flex justify-content-between align-items-center rounded-0 collapsed" style="border-bottom: 1px solid;" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
													<span class="h3 text-primary">Requisitos</span>
													<img src="@/assets/image/down-arrow.webp" alt="">
												</button>
											</h2>
										</div>

										<div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#preguntasAccordion">
											<div class="card-body">
												<div class="pr-md-5">
													<ul>
														<li>DPI vigente</li>
														<li>Recibo de agua, luz ó teléfono fijo</li>
														<li>Fondos a invertirse deben estar bancarizados</li>
													</ul>
													<p>
														<strong>Si eres una persona jurídica (todo lo anterior) y adicional a:</strong>
													</p>
													<ul>
														<li>Copia de nombramiento del representante legal</li>
														<li>DPI del representante legal</li>
														<li>Copia escritura constitución sociedad</li>
														<li>Copia de patentes de comercio y sociedad</li>
														<li>Copia de RTU de la empresa</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-12 mb-3">
							<div class="row">
								<div class="col-lg-6">
									<h2 class="mb-2 mb-lg-4 font-weight-bold">¿Porqué es una buena opción?</h2>
								</div>
								<div class="col-lg-6">
									<div class="accordion" id="preguntasAccordion">
										<div class="" id="headingTwo">
											<h2 class="mb-0">
												<button class="btn btn-block text-left d-flex justify-content-between align-items-center rounded-0 collapsed" style="border-bottom: 1px solid;" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
													<span class="h3 mb-0 text-primary">Beneficios</span>
													<img src="@/assets/image/down-arrow.webp" alt="">
												</button>
											</h2>
										</div>

										<div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#preguntasAccordion">
											<div class="card-body">
												<div class="pr-md-5">
													<p>Tasas de retorno anual: desde 10% anual (histórico marca ingresos hasta de 58.80% anual en algunos casos dependiendo de el tipo de crédito realizado).</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-md-12" v-if="false">
					<Inspeccion />
				</div>
			</div>
			<router-view></router-view>
		</div>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'
	
	const ShareSocial = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "ShareSocial" */"@/components/ShareSocial.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const BtnLoading = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "BtnLoading" */"@/components/BtnLoading.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const Pool = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Pool" */"@/components/Pool.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const Inspeccion = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Inspeccion-inmueblesShow" */"@/components/inspeccion-inmuebles/Show.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const CurrencyInput = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Currency" */"@/components/Currency.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	const InputDate = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "formsDate" */"@/components/forms/Date.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const Categorias = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "CatalogosCategorias" */"@/components/catalogos/Categorias.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	export default {
		components: {
			Pool,
			Inspeccion,
			ShareSocial,
			CurrencyInput,
			InputDate,
			Categorias,
			BtnLoading
		},
		data(){
			return {
				loadingPublicacion: false,
				loadingSave: false,
				loadingPublicacionFotos: false,

				editarPrenda: false,

				form: {
					idprenda: 0,
					nombre: '',
					descripcion: '',
					precio: '',
					idcategoria: '',
					tipo: '',
					pago: '',
					epoch: '',
					fechaPublicada: '',
					rendimiento: '',
					tipoCuota: '',
					valorCarro: '',
					interes: '',
					fechaContratos: '',
					tipoDesembolso: '',
					inversionista: '',
					estado: '',
					notificarInversionista: false
				},

				fotoSeleccionada: {}
			}
		},
		mounted(){
			this.getPublicacion()
			this.getPublicacionFotos()
		},
		methods: {
			editarHandler(val = true){
				this.editarPrenda = val
				
				if(val){
					this.form.idcategoria = this.prenda.idcategoria
					this.form.tipo = this.prenda.idtipo
					this.form.descripcion = this.prenda.descripcion

					//this.form.epoch = this.prenda.fechaPublicada
					if(this.prenda.fechaPublicada){
						var parts = this.prenda.fechaPublicada.split('/')
						this.form.epoch = `${parts[2]}-${parts[1]}-${parts[0]}`
						this.form.fechaPublicada = this.prenda.fechaPublicada
					}

					this.form.precio = this.prenda.precio
					this.form.nombre = this.prenda.nombre
					this.form.interes = this.prenda.interes
					this.form.pago = this.prenda.pago
					this.form.inversionista = this.prenda.inversionista || 1

					this.form.tipoCuota = this.prenda.tipoCuota
					this.form.valorCarro = this.prenda.valorCarro
					this.form.fechaContratos = this.prenda.fechaContratos
					this.form.tipoDesembolso = this.prenda.tipoDesembolso
					
					this.form.estado = this.prenda.est || 0
					this.form.notificarInversionista = false
				}
			},

			saveHandler(){
				this.loadingSave = true
				if(this.form.estado != 1){
					this.form.notificarInversionista = false
				}

				if(this.form.epoch){
					var parts = this.form.epoch.split('-')
					var myDate = new Date(parts[0], parts[1]-1, parts[2])
					var epoch = myDate.getTime()/1000
					this.form.fechaPublicada = epoch
				}

				this.form.idprenda = this.codigo
				this.$store.dispatch('publicacionUpdate', this.form).then(response => {
					this.editarPrenda = false

					if(this.form.estado == 1 && this.form.notificarInversionista){
						var data = this.prenda
						data.notificacion = this.form.notificarInversionista
						data.fotoPrenda = (this.fotos[0] || {}).ruta
						this.$store.dispatch('sendAprobacion', data)
					}

					this.getPublicacion()
					this.getPublicacionFotos()
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingSave = false
				})
			},
			print(){
				window.print();
			},
			copy(){
				navigator.clipboard.writeText(window.location.href);
				window.toastr.info('Se ha copiado correctamente al portapapeles.')
			},
			getPublicacion(){
				this.loadingPublicacion = true
				this.$store.dispatch('getPublicacion',{
					idprenda: this.codigo,
					idusuario: this.user.idusuario || 0
				}).then(response => {
					var prendas = response.data || []
					var prenda = prendas[0] || {}
					this.form.interes = prenda.interes || 0

					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingPublicacion = false
				})
			},
			getPublicacionFotos(){
				this.loadingPublicacion = true
				this.$store.dispatch('getPublicacionFotos',{
					idprenda: this.codigo
				}).then(response => {
					var fotos = response.data
					this.fotoSeleccionada = fotos[0] || {}
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingPublicacion = false
				})
			},
			prendaDeleteFavorite(){
				this.$store.dispatch('prendaDeleteFavorite', this.forms).then(response => {
					this.getPublicacion()
					window.toastr.info('Favorito eliminado.', 'Aviso')
					return response
				}).catch(error =>{
					return error
				})
			},
			prendaAddFavorite(){
				this.$store.dispatch('prendaAddFavorite', this.forms).then(response => {
					this.getPublicacion()
					window.toastr.info('Favorito agregado.', 'Aviso')
					return response
				}).catch(error =>{
					return error
				})
			},
			selectFoto(val){
				this.fotoSeleccionada = val
			},
			trunc(x, posiciones = 0) {
				var s = x.toString()
				var l = s.length
				var decimalLength = s.indexOf('.') + 1
				if (l - decimalLength <= posiciones){
				  return x
				}
				var isNeg  = x < 0
				var decimal =  x % 1
				var entera  = isNeg ? Math.ceil(x) : Math.floor(x)
				var decimalFormated = Math.floor(
				  Math.abs(decimal) * Math.pow(10, posiciones)
				)
				var finalNum = entera + 
				  ((decimalFormated / Math.pow(10, posiciones))*(isNeg ? -1 : 1))
				return finalNum
			}
		},
		computed: {
			codigo(){
				return this.$route.params.id || -1
			},
			publicacion(){
				return this.$store.getters.publicacion || []
			},
			prenda(){
				return this.publicacion[0] || {}
			},
			rendimiento(){
				return (((this.form.interes || 0) * 1.12) * 0.7) * 12
			},
			fotos(){
				return this.$store.getters.publicacionFotos || []
			},
			isLoggedIn(){
				return this.$store.getters.isLoggedIn
			},
			user(){
				return this.$store.getters.currentUser || {}
			},
			sharing(){
				var tags = [
					'clubcashin'
				];
				if (this.prenda.categoria) {
					tags.push(this.prenda.categoria)
				}
				if (this.prenda.tipo) {
					tags.push(this.prenda.tipo)
				}

				return {
					url: window.location.href || '',
					title: this.prenda.nombre || '',
					description: this.prenda.descripcion || '',
					quote: '',
					hashtags: tags.join(','),
					twitterUser: ''
				}
			},
			networks(){
				return [
					{network:'facebook', nombre: 'Facebook', class:'', style: '', icon: [ 'fab',  'facebook-f']},
					{network:'whatsapp', nombre: 'Whatsapp', class:'', style: '', icon: [ 'fab',  'whatsapp']},
					{network:'twitter', nombre: 'Twitter', class:'', style: '', icon: [ 'fab',  'twitter']},
					{network:'sms', nombre: 'SMS', class:'', icon: 'sms'},
					{network:'email', nombre: 'Email', class:'', icon: 'envelope-open-text'}
				]
			},
			porcentajePrenda(){
				var montoRecaudado = this.prenda.montoRecaudado || 0
				var precio = this.prenda.precio || 1
				return this.trunc(((montoRecaudado*100)/precio), 2)
			},
			forms(){
				return {
					idusuario: this.user.idusuario,
					idprenda: this.codigo,
					fecha: Math.round(new Date().getTime()/1000.0)
				}
			},
			isMobile() {
				return this.$store.getters.isMobile
			},
		}
	}
</script>
